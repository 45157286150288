html,body{
  width: 100%;
  height: 100%;
  background-color: #111111;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

::selection {
  color: #5B18C2;        /* Text color */
  background: #00FFD1;   /* Background color */
}

::-moz-selection {
  color: #5B18C2;        /* Text color */
  background: #00FFD1;   /* Background color */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'amoera Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/amoera.woff') format('woff');
  }

@font-face {
  font-family: 'MADE Carving Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/madeCarving-regular.otf') format('opentype');
}

@font-face {
  font-family: 'MADE Carving Light';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/madeCarving-light.otf') format('opentype');
}

@font-face {
  font-family: 'MADE Carving ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/madeCarving-extralight.otf') format('opentype');
}

@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 100 900;
  src: url('./assets/fonts/jetbrains-mono.ttf') format('truetype');
}